import React, { useEffect, useState } from "react"
import {
  MonashDataProcedure_ExternalLink,
  MonashPrivacyPolicy_ExternalLink,
} from "../../utils/urls"

// import {
//   DataProtectionAndPrivacyProcedure_ExternalLink,
//   TermsAndConditionsS3_ExternalLink,
// } from "../../../utils/urls"
import CheckBoxInput from "../form/CheckBoxInput"
import Form from "../form/Form"
import AppLogo from "../logos"
import SidebarWrapper from "./SidebarWrapper"

interface iSignUpFormConsentPanel {
  onChange: (consent: boolean) => void
  error: boolean
}

const Panel: React.FC<{
  open: boolean
  onToggleOpen: (open: boolean) => void
  onChange: (data: any) => void
}> = ({ open, onToggleOpen, onChange }) => {
  return (
    <SidebarWrapper sidebarOpen={open} onToggleSidebar={onToggleOpen}>
      <div className="py-8 px-4 sm:px-8 overflow-y-auto text-site-blue-dark">
        <div className="flex items-center pb-4">
          <AppLogo width={10} />

          <p className="text-gray-600 border-l ml-2 mb-0 pl-2 text-xs">
            AskEM
            <br />
            Terms of Use &amp; Privacy Policy
          </p>
        </div>

        <div style={{ maxWidth: "560px" }}>
          <p className="text-sm">
            Monash University would like to collect information (which includes
            information about your health) and as set out in the{" "}
            <a
              href={MonashPrivacyPolicy_ExternalLink}
              target="_blank"
              rel="noreferrer"
              className="underline font-bold"
            >
              AskEarlyMenopause terms and conditions and privacy policy
            </a>
            . In processing your personal information, Monash University and
            MCHRI would like to use your personal information to create a user
            profile and use the information for future AskEarlyMenopause
            research. If Monash University wants to use identifiable information
            about you for future research, we will reach out to you using the
            contact information you provide to ask for your specific consent for
            this use. Monash may use de-identified information that you provide
            for Early Menopause research purposes - if you do not want your
            de-identified information to be used in this way please reach out to
            askem.admin@monash.edu{" "}
            <a
              href="mailto:askem.admin@monash.edu"
              target="_blank"
              rel="noreferrer"
              className="font-bold"
            >
              askem.admin@monash.edu
            </a>
            .
          </p>
          {/* <ContentSeparator />
          <ContentSeparator /> */}
          <Form
            useDiv={true}
            data={{
              term1: false,
              term2: false,
              term3: false,
            }}
            onSubmit={() => {}}
            onChange={onChange}
          >
            {(data, setData) => {
              return (
                <>
                  <CheckBoxInput
                    label={
                      <span>
                        I consent to the personal information that I provide to
                        be used by Monash University for the purposes of
                        creating a user profile.
                      </span>
                    }
                    onClick={() => setData("term1", !data.term1)}
                    value={data.term1}
                    disable={false}
                  />
                  {/* <ContentSeparator /> */}
                  <CheckBoxInput
                    label={
                      <span>
                        I consent to the information that I provide to be used
                        by MCHRI and Monash University to improve Early
                        Menopause guidelines.
                      </span>
                    }
                    onClick={() => setData("term2", !data.term2)}
                    value={data.term2}
                    disable={false}
                  />
                  {/* <ContentSeparator /> */}
                  <CheckBoxInput
                    label={
                      <span>
                        I have read and understood the{" "}
                        <a
                          href={MonashPrivacyPolicy_ExternalLink}
                          target="_blank"
                          rel="noreferrer"
                          className="underline font-bold"
                        >
                          AskEM terms and conditions and privacy policy
                        </a>{" "}
                        and the Monash University{" "}
                        <a
                          href={MonashDataProcedure_ExternalLink}
                          target="_blank"
                          rel="noreferrer"
                          className="underline font-bold"
                        >
                          Data Protection and Privacy Procedure
                        </a>
                        .
                      </span>
                    }
                    disableClickOnContent={true}
                    onClick={() => setData("term3", !data.term3)}
                    value={data.term3}
                    disable={false}
                  />
                </>
              )
            }}
          </Form>

          {/* <ContentSeparator />
          <ContentSeparator /> */}
          <p className="text-sm italic">
            If you do not provide your consent for the above purposes, Monash
            University will not be able to create a user profile for the
            AskEarlyMenopause application.
          </p>
          {/* <ContentSeparator /> */}
          <p className="text-sm">
            Monash University values the privacy of every individual’s personal
            information and is committed to the protection of that information
            from unauthorised use and disclosure except where permitted by law.
            For more information about Data Protection and Privacy at Monash
            University please see our{" "}
            <a
              href={MonashDataProcedure_ExternalLink}
              target="_blank"
              rel="noreferrer"
              className="underline font-bold"
            >
              Data Protection and Privacy Procedure
            </a>
            .
            <br />
            <br />
            If you have any questions about how Monash University is collecting
            and handling your personal information, please contact our Data
            Protection and Privacy Office at{" "}
            <a
              href="mailto:dataprotectionofficer@monash.edu"
              target="_blank"
              rel="noreferrer"
              className="font-bold"
            >
              dataprotectionofficer@monash.edu
            </a>
          </p>
          {/* <ContentSeparator /> */}
        </div>
      </div>
    </SidebarWrapper>
  )
}

const SignUpFormConsentPanel: React.FC<iSignUpFormConsentPanel> = ({
  onChange,
  error,
}) => {
  const [openPanel, setOpenPanel] = useState(false)
  const [consent, setConsent] = useState(false)
  useEffect(() => {
    onChange(consent)
    if (consent) {
      setOpenPanel(false)
    }
  }, [consent])

  return (
    <>
      <CheckBoxInput
        label={
          <span>
            I have read and agree to the{" "}
            <span className="font-bold hover:underline">
              terms and conditions
            </span>{" "}
            of the AskPCOS application.
          </span>
        }
        onClick={() => {
          setOpenPanel(true)
        }}
        value={consent}
        disable={false}
        error={error}
      />
      <Panel
        open={openPanel}
        onToggleOpen={(open: boolean) => setOpenPanel(open)}
        onChange={data => setConsent(data.term1 && data.term2 && data.term3)}
      />
    </>
  )
}

export default SignUpFormConsentPanel
