import { useMachine } from "@xstate/react"
import { Auth } from "aws-amplify"
import { Link } from "gatsby"
import React, { useState } from "react"
import { Machine } from "xstate"

import { FaUserPlus } from "react-icons/fa"
import { Login_InternalLink } from "../../utils/urls"
import ErrorText from "../ErrorText"
import RegistrationFormCard from "../cards/RegistrationFormCard"
import Form from "../form/Form"
import InputSeperator from "../form/InputSeperator"
import PleaseWaitButton from "../form/PleaseWaitButton"
import SubmitButton from "../form/SubmitButton"
import TextInput from "../form/TextInput"
import ConfirmationForm from "./ConfirmationForm"
import SignUpFormConsentPanel from "./SignUpFormConsentPanel"
import ConfirmationSuccess from "./ConfirmationSuccess"
import PasswordHelpText from "./PasswordHelpText"
import { withAmplifyAuth } from "./withAmplifyAuth"

interface iSignUpFormData {
  email: string
  password: string
  confirm_password: string
}

enum ErrorType {
  password,
  email,
  confirm_password,
  consent,
}

interface iSignUpFormError {
  text: string | null
  type: ErrorType | null
}

const ConclusionText = () => (
  <p className="text-xs mt-4 text-site-blue-dark">
    Already have an account?&nbsp;
    <span>
      <Link className="underline hover:font-bold" to={Login_InternalLink}>
        Log in
      </Link>
    </span>
    &nbsp;or&nbsp;
    <span>
      <Link className="underline hover:font-bold" to="/">
        Home
      </Link>
    </span>
  </p>
)

const SignUpSubmitButton: React.FC<{ loading: boolean }> = ({ loading }) => {
  if (!loading) return <SubmitButton title="Sign Up" Icon={<FaUserPlus />} />
  return <PleaseWaitButton />
}

const SignUpFormView: React.FC<{
  onSubmit: (data: iSignUpFormData) => void
  error: iSignUpFormError
  loading: boolean
  show: boolean
}> = ({ onSubmit, error, loading, show }) => {
  if (!show) return null
  const formData: iSignUpFormData = {
    email: "",
    password: "",
    confirm_password: "",
  }

  return (
    <RegistrationFormCard title_1="Ask Early Menopause" title_2="Sign up">
      <ErrorText text={error.text} />
      <Form data={formData} onSubmit={onSubmit}>
        {(data, setData) => {
          return (
            <div className="text-site-blue-dark">
              <InputSeperator />
              <TextInput
                label="Email Address"
                onChange={(e: any) => setData("email", e.target.value)}
                placeholder="Please enter your email address"
                value={data.email}
                type="email"
                error={error.type === ErrorType.email}
                disable={loading}
              />
              <InputSeperator />
              <TextInput
                label="Password"
                onChange={(e: any) => setData("password", e.target.value)}
                placeholder="Please enter your password"
                value={data.password}
                type="password"
                error={error.type === ErrorType.password}
                disable={loading}
              />
              <InputSeperator />
              <PasswordHelpText
                show={
                  error.type === ErrorType.password || data.password.length > 0
                }
              />
              <TextInput
                label="Confirm password"
                onChange={(e: any) =>
                  setData("confirm_password", e.target.value)
                }
                placeholder="Please confirm your password"
                value={data.confirm_password}
                type="password"
                error={error.type === ErrorType.confirm_password}
                disable={loading}
              />
              <InputSeperator />
              <InputSeperator />
              <SignUpFormConsentPanel
                error={error.type === ErrorType.consent}
                onChange={_consent => setData("consent", _consent)}
              />
              <SignUpSubmitButton loading={loading} />
            </div>
          )
        }}
      </Form>
      <ConclusionText />
    </RegistrationFormCard>
  )
}

const SignUpForm: React.FC<{ Auth: typeof Auth }> = ({ Auth }) => {
  const [error, setError] = useState<iSignUpFormError>({
    text: null,
    type: null,
  })

  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState("")

  const signUpFlow = Machine({
    id: "signup-flow-machine",
    initial: "signUp",
    states: {
      signUp: {
        on: {
          USER_NOT_CONFIRMED: "confirmation",
          USER_CONFIRMED: "done",
        },
      },
      confirmation: {
        on: {
          USER_CONFIRMED: "done",
        },
      },
      done: { type: "final" },
    },
  })

  const [currentSignUpState, sendSignUpState] = useMachine(signUpFlow)

  const validateInput = ({
    email,
    password,
    confirm_password,
  }: iSignUpFormData) => {
    if (!email)
      return { text: "Please, enter an email address", type: ErrorType.email }
    if (!password)
      return {
        text: "Please, enter a valid password",
        type: ErrorType.password,
      }
    if (password.length < 8)
      return {
        text: "The password must have at least 8 characters",
        type: ErrorType.password,
      }
    if (!confirm_password)
      return {
        text: "Please, confirm your password",
        type: ErrorType.confirm_password,
      }
    if (password !== confirm_password)
      return {
        text: "Passwords don't match",
        type: ErrorType.confirm_password,
      }
    return null
  }

  const onUserSignUpError = (err: any) => {
    if (err.code === "UsernameExistsException")
      return {
        type: ErrorType.email,
        text: err.message,
      }
    if (err.code === "InvalidPasswordException")
      return {
        type: ErrorType.password,
        text: err.message,
      }
    return {
      type: null,
      text: err.message,
    }
  }

  const onSubmit = ({ email, password, confirm_password }: iSignUpFormData) => {
    if (loading) return
    setError({
      text: null,
      type: null,
    })
    const err = validateInput({ email, password, confirm_password })
    if (err) {
      setError(err)
    } else {
      setLoading(false)
      Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      })
        .then(user => {
          setLoading(false)
          setUsername(email)
          if (!user.userConfirmed) sendSignUpState("USER_NOT_CONFIRMED")
          else sendSignUpState("USER_CONFIRMED")
        })
        .catch(err => {
          setLoading(false)
          setError(onUserSignUpError(err))
          console.log(err)
        })
    }
  }

  const onConfirmationSuccess = () => {
    sendSignUpState("USER_CONFIRMED")
  }

  return (
    <>
      <SignUpFormView
        onSubmit={onSubmit}
        error={error}
        loading={loading}
        show={currentSignUpState.matches("signUp")}
      />
      <ConfirmationForm
        Auth={Auth}
        Username={username}
        show={currentSignUpState.matches("confirmation")}
        onSuccess={onConfirmationSuccess}
      />
      <ConfirmationSuccess show={currentSignUpState.matches("done")} />
    </>
  )
}

export default withAmplifyAuth(SignUpForm)
