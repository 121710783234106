import React from "react"

import { FaRegCheckSquare, FaRegSquare } from "react-icons/fa"

interface iCheckBoxInput {
  label: any
  onClick: () => void
  value: boolean
  disable: boolean
  error?: boolean
  disableClickOnContent?: boolean
}

const CheckBoxInput: React.FC<iCheckBoxInput> = ({
  label,
  onClick,
  value,
  disable,
  error,
  disableClickOnContent,
}) => {
  return (
    <div className="flex">
      <p
        className={`flex items-start ${
          !disableClickOnContent && "cursor-pointer"
        } text-sm ${error && "p-1 rounded  border border-red-700"}`}
        onClick={() => {
          if (disable) return
          if (disableClickOnContent) return
          onClick()
        }}
      >
        <span
          className={`inline-block ${
            disableClickOnContent && "cursor-pointer"
          }`}
          style={{ marginTop: "3px" }}
          onClick={e => {
            if (disableClickOnContent) {
              onClick()
              e.stopPropagation()
            }
          }}
        >
          {value ? <FaRegCheckSquare /> : <FaRegSquare />}
        </span>
        <span className="px-1" />
        <span className="text-sm">{label}</span>
      </p>
    </div>
  )
}

export default CheckBoxInput
