// import Layout from "components/layout"
import SEO from "components/seo"
import React from "react"
import theme from "components/layout/theme"
import SignUpPage from "components/registration/SignUpPage/SignUpPage"

const SignUp = () => {
  return (
    <div style={{ background: theme.colors.primary }}>
      <SEO title="SignUp" />
      <SignUpPage />
    </div>
  )
}

export default SignUp
