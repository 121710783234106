import { Link } from "gatsby"
import React from "react"

import { FaUnlock } from "react-icons/fa"
import { Login_InternalLink } from "../../utils/urls"
import RegistrationFormCard from "../cards/RegistrationFormCard"
import SubmitButton from "../form/SubmitButton"

const ConfirmationSuccessView = () => {
  return (
    <RegistrationFormCard
      title_1="Ask Early Menopause"
      title_2="Congratulations"
    >
      <p className="text-site-blue-dark">
        Thank you for joining our platform. You account has been activated. Go
        ahead and <strong>log in</strong>.
      </p>

      <div className="mt-4">
        <Link
          to={`${Login_InternalLink}?returnUrl=${encodeURIComponent(
            "/profile?popup=true"
          )}`}
        >
          <SubmitButton title="Log in" Icon={<FaUnlock />} />
        </Link>
      </div>
    </RegistrationFormCard>
  )
}

const ConfirmationSuccess: React.FC<{ show: boolean }> = ({ show }) => {
  if (!show) return null
  return <ConfirmationSuccessView />
}

export default ConfirmationSuccess
