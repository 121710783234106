import React from "react"

import { MCHRI_ExternalLink } from "../../../utils/urls"
import SignUpForm from "../SignUpForm"

const SignUpPage = () => {
  return (
    <section className="xxl:container xxl:mx-auto xxl:bg-white">
      <div className="w-full py-12 flex min-h-screen justify-center px-3 bg-site-pink">
        <div style={{ width: "396px" }}>
          <SignUpForm />
          <p className="text-center mt-8 text-sm hover:underline">
            <a
              href={MCHRI_ExternalLink}
              target="_blank"
              className="hover:gray-link"
            >
              Powered By <br />
              Monash Centre for Health Research & Implementation (MCHRI)
            </a>
          </p>
        </div>
      </div>
    </section>
  )
}

export default SignUpPage
